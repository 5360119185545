<template>
    <div>
        <el-tabs v-model="activeTab">
            <el-tab-pane label="维修单" name="1">
                <el-card class="box-card">
                    <div class="box-title">
                        基本信息
                    </div>
                    <MXDoubleInfo :items="basicInfoItems" />
                </el-card>
                <el-card class="box-card">
                    <div class="box-title">
                        位置信息
                    </div>
                    <div style="padding: 10px 0">
                        <MXLocGeocoder title="签到位置" :locInfo="signInLocInfo" />
                        <MXLocGeocoder title="签退位置" :locInfo="signOutLocInfo" />
                    </div>
                </el-card>
                <el-card class="box-card">
                    <div class="box-title">
                        其它信息
                    </div>
                    <div style="padding: 10px 0">
                        <MXInput label="故障现象" :value="form.faultPhenomenon?form.faultPhenomenon:'无'" :disabled="true" />
                        <div style="padding: 10px 0">
                            <MXPreview title="现场图片" :images="imageList" />
                        </div>
                        <MXInput label="情况描述" :value="form.faultReason?form.faultReason:'无'" :disabled="true" />
                    </div>
                </el-card>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import store from "@/store";
import { mapGetters } from 'vuex'
import Moment from 'moment'
//import { getDictDatas, DICT_TYPE } from '@/utils/dict'
import { getDate } from "@/utils/dateUtils";
import { getFaultPhenomenon } from "@/common/utils/dict";

import { getElevatorRepair, maintenanceOrderApprove } from "@/api/maintenance/repair";
// import { getFileByBizId } from "@/api/infra/file";
import MXLocGeocoder from '@/pages/components/mx-biz-components/mx-loc-geocoder'
import MXDoubleInfo from '@/pages/components/mx-biz-components/mx-double-info'
import MXInput from '@/pages/components/mx-ui-components/mx-input'
import MXPreview from '@/pages/components/mx-ui-components/mx-preview'

import { getDictValue } from '@/utils/constants';
export default {
    name: 'execute',
    props: ['formData', 'showOperate'],
    components: {
        MXDoubleInfo,
        MXInput,
        MXLocGeocoder,
        MXPreview
    },
    data() {
        return {
            dialogShow: this.show,
            loading: false,
            appoveBtnLoading: false,
            activeTab: '1',
            form: {
                workOrderId: null,
                elevatorName: '',
                shouldCompleteDate: '', // 应完成日期
                startMaintenanceTime: '', // 开始保养时间
                endMaintenanceTime: '', // 结束保养时间
                maintenanceOrderType: null, // 保养工单类型（1:半月保 2:季保1 3:半年保 4:季保2 5:年保）
                dealEmployeeId: '', // 具体处理人员id（工单状态为已完成时的人员信息）
                issues: '', // 主要问题
                issueHandling: '', // 主要问题处理情况
                // finishWorkOrder: false // 是否要工单完成
                fittingInfo: '', // 更换配件
                // finishWorkOrder: false // 是否要工单完成
                itemIds: [], // 维保细则
            },
            list: [],
            groupList: {},
            rules: {},
            datePicker: {
                disabledDate: (time) => {
                    if (this.detail.minShouldCompleteDate && this.detail.maxShouldCompleteDate) {
                        return time.getTime() < new Moment(this.detail.minShouldCompleteDate) || time.getTime() > new Moment(this.detail.maxShouldCompleteDate)
                    } else {
                        return false
                    }
                }
            },

            basicInfoItems: [],
            signInLocInfo: {},
            signOutLocInfo: {},
            imageList: [],
        }
    },
    computed: {
        ...mapGetters({})
    },
    mounted() {},
    methods: {
        getBy(params) {
            this.getDetailById(params.id)
        },
        getDetailById(orderId) {
            getElevatorRepair(orderId).then((res) => {
                this.form = res.info || {}
                this.basicInfoItems = this.parseBaseInfoItems(res.info)
                this.form.faultPhenomenon = this.parseFaultPhenomenon(res.info.faultType,res.info.elevatorType)
                this.imageList = this.parseImageList(res.info)
                let locInfo = this.parseLocationAddress(res.info)
                this.signInLocInfo = {
                    lon: locInfo.signInLon,
                    lat: locInfo.signInLat
                }
                this.signOutLocInfo = {
                    lon: locInfo.signOutLon,
                    lat: locInfo.signOutLat
                }
            })
        },
        parseBaseInfoItems(data) {
            let list = [{
                    'label': '工单号',
                    'value': data.orderCode
                },
                {
                    'label': '小区名称',
                    'value': data.communityName ? data.communityName : '--'
                }, {
                    'label': '电梯类型',
                    'value': getDictValue('elevator', data.elevatorType)
                }, {
                    'label': '电梯编号',
                    'value': data.elevatorCode
                }, {
                    'label': '注册码',
                    'value': data.registrationCode
                },
                {
                    'label': '安装地址',
                    'value': data.installAddress
                },
                {
                    'label': '第一维保人',
                    'value': data.maintainPrincipalPerson
                },
                {
                    'label': '第二维保人',
                    'value': data.maintainSubordinatePerson
                },
                {
                    'label': '安全管理员',
                    'value': data.propertySafeMan
                },
                {
                    'label': '上报时间',
                    'value': this.parseTime(data.reportTime)
                },
            ]
            return list

        },
        parseFaultPhenomenon(faultType, elevatorType) {
            let dictArr = []
            if (elevatorType == 0 || elevatorType == 1 || elevatorType == 2) {
                dictArr = getFaultPhenomenon(1)
            } else if (elevatorType == 3) {
                dictArr = getFaultPhenomenon(2)
            } else if (elevatorType == 4) {
                dictArr = getFaultPhenomenon(3)
            }
            let obj = dictArr.find((item)=> {
                return item.value == faultType
            })
            if (obj) {
                return obj.label
            }else {
                return null
            }
        },
        parseImageList(data) {
            if (data.faultImageUrl && data.faultImageUrl.length) {
                return JSON.parse(data.faultImageUrl)
            }
            return []
        },
        parseLocationAddress(data) {
            return {
                signInLon: data.installLongitude,
                signInLat: data.installLatitude,
                signOutLon: data.signOutLongitude,
                signOutLat: data.signOutLatitude,
            }
        },
        getDateStar(ms) {
            return getDate(ms);
        },
    },
    watch: {
        show(val) {
            this.dialogShow = val
        }
    }
}
</script>
<style lang="scss" scoped>
.workOrderDialog {
    ::v-deep {
        .el-dialog__body {
            padding: 0 20px 20px;
        }
    }
}

.form {
    ::v-deep {
        .el-input {
            width: 200px;
        }

        .el-textarea {
            width: 420px;
        }
    }

}

.block {
    margin-right: 10px;
}

.box-card:not(first-of-type) {
    margin-top: 22px;
    width: 100%;
    padding: 0px !important;
}

.box-card:last-of-type {
    margin-bottom: 22px;
}

.box-title {
    font-size: 14px;
    font-weight: 700;
    color: #333333;
}

.box-content {
    clear: both;
    font-size: 13px;
    padding-top: 14px;
}

.box-content:last-of-type {
    overflow: hidden;
    margin-top: 14px;
}

.box-content-left {
    float: left;
}

.box-content-left>span {
    padding-left: 4px;
}

.box-content-left:after {
    clear: both;
}

.box-content-right {
    text-align: left;
    float: right;
}

.box-content-right>span {
    padding-left: 4px;
}

.box-title-span {
    float: right;
    font-size: 12px;
}

.bg-dark {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    background-color: #f5f6f8;
}

.box-item {
    font-size: 12px;
    padding: 8px 20px;
    border-bottom: 1px solid #F3F3F3;
}


.item-status-unfinished {
    color: red;
}

.box-img>div {
    margin-top: 10px;
    padding-left: 20px;
    font-size: 9px;
    color: #666666;
}

.operate-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>