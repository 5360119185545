<template>
  <div>
    <div class="data-wrapper">
      <div class="data-content">
        <MXElevatorSelector @selectElevator="onSelectElevator" />
        <el-form ref="ruleForm">
          <div class="widget-wrapper flex flex-wrap">
            <div class="line-wrapper padding-lr-10" v-if="item.label" :class="item.width?'width-'+item.width:'width-50'" v-for="(item, index) in dataList">
              <MXFormItemParser :item="item" @onInput="onInput" @onSelect="onSelect" @onSelectRegion="onSelectRegion" @onSelectDate="onSelectDate" />
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { elevatorRepairOrderBpmInitiated } from "@/api/maintenance/repair";
import MXElevatorSelector from '@/pages/components/mx-biz-components/mx-elevator-selector'
import { datasInstance } from '@/pages/maintenance/repair/datas'
import MXFormItemParser from '@/pages/components/mx-ui-components/mx-form-item-parser'
export default {
  name: 'customerUpdate',
  components: {
    MXElevatorSelector,
    MXFormItemParser
  },
  data() {
    return {
      dataId: null,
      dataList: [], //小部件数据源集合
    }
  },
  mounted() {
    this.dataList = this.defaultDataList()
  },
  methods: {
    /**
     * 根据数据更新
     */
    updateBy(data) {

    },
    /**
     * 根据参数请求获取数据
     */
    getBy(params) {
      // this.dataId = params.id
      // getContractCustomer(params.id).then((res) => {
      //   let data = res.data
      //   this.dataList = datasInstance().parseDetailResponse(this.defaultDataList(), data)
      // })
    },
    submit(callback) {
      if (this.selectElevator) {
        let params = this.buildParams(this.dataList)
        params.elevatorCode = this.selectElevator.elevatorCode
        params.elevatorName = this.selectElevator.elevatorName
        params.installAddress = this.selectElevator.address
        elevatorRepairOrderBpmInitiated(params).then((res => {
          if (callback) callback(true)
        })).catch((err) => {
          if (callback) callback(false)
        })
      } else {

      }
    },
    buildParams(dataList) {
      let params = {}
      dataList.forEach((item) => {
        params[item.key] = item.submitValue ? item.submitValue : item.value
      })
      return params
    },
    defaultDataList() {

      return [{
          'key': 'id',
        },
        // {
        //   'key': 'elevatorCode',
        //   'label': '电梯编号',
        //   'ui': 'mx-select',
        // },
        // {
        //   'key': 'address',
        //   'label': '地址',
        //   'ui': 'mx-input',
        // },
        // {
        //   'key': 'leader',
        //   'label': '责任人',
        //   'ui': 'mx-select',
        // },
        {
          'key': 'faultType',
          'label': '故障类型',
          'ui': 'mx-select',
          'api': 'admin-api/system/dict-data/page-auth-less?pageNo=1&pageSize=10&dictType=fault_type',
          'dataKey': 'data.list',
          'dataLabel': 'label',
          'dataValue': 'value',
        },
        {
          'key': 'faultSecondType',
          'label': '故障小类型',
          'ui': 'mx-select',
          'api': 'admin-api/system/dict-data/page-auth-less?pageNo=1&pageSize=10&dictType=fault_sub_type',
          'dataKey': 'data.list',
          'dataLabel': 'label',
          'dataValue': 'value',
        },
        {
          'key': 'reportTime',
          'label': '报修时间',
          'ui': 'mx-date',
        },
        {
          'key': 'reportFloor',
          'label': '报修楼层',
          'ui': 'mx-input',
        },
        // {
        //   'key': 'arrivePhotos',
        //   'label': '到达现场图片',
        //   'ui': 'mx-attachment',
        // },
        // {
        //   'key': 'desc',
        //   'label': '救援过程描述',
        //   'ui': 'mx-textarea',
        // },
      ]
    },
    updateItemByIdenfier(value, identifier) {
      let find = this.dataList.find((item) => {
        return item.key == identifier
      })
      if (find)
        find.submitValue = value
    },
    onInput(e) {
      this.updateItemByIdenfier(e.value, e.identifier)
    },
    onSelect(e) {
      this.updateItemByIdenfier(e.value, e.identifier)
    },
    onSelectRegion(e) {
      let find = this.dataList.find((item) => {
        return item.key == e.identifier
      })
      find.composeKey.forEach((k, index) => {
        let _object = this.dataList.find((item) => {
          return item.key == k
        })

        if (_object) {
          _object.submitValue = e.value[index]
        } else {
          this.dataList.push({
            key: k,
            submitValue: e.value[index]
          })
        }
      })
    },
    onSelectDate(e) {
      this.updateItemByIdenfier(e.value, e.identifier)
    },
    onSelectFile(e) {
      console.log("selectFile", e)
      // 修改问参数
    },
    onSelectElevator(elevator) {
      this.selectElevator = elevator
    },
  }
}

</script>
<style lang="scss" scoped>
.line-wrapper {
  width: 50%;
}

</style>
