<template>
  <div class="save-wrapper">
    <div class="content-wrapper">
      <Update ref="update" />
    </div>
    <div class="footer-wrapper">
      <el-button type="primary" @click="onSubmit">提 交</el-button>
      <el-button v-if="dataId" @click="onCancel">取 消</el-button>
    </div>
  </div>
</template>
<script>
import { Loading } from 'element-ui';
import Update from '@/pages/maintenance/repair/components/update'
export default {
  name: 'CustomerSave',
  components: {
    Update
  },
  data() {
    return {
      dataId: null,
    }
  },
  methods: {
    /**
     * 根据数据更新
     */
    updateBy(data) {

    },
    /**
     * 根据参数请求获取数据
     */
    getBy(params) {
      this.dataId = params.id
      this.$refs.update.getBy(params)
    },
    onSubmit() {
      let loadingInstance = Loading.service();
      this.$refs.update.submit((success) => {
        loadingInstance.close();
        if (success)
          this.$emit('afterSubmit',this.dataId)
      })
    },
    onCancel() {
      this.$emit('cancel', this.dataId)
    }
  }
}

</script>
<style lang="scss" scoped>
.save-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
  overflow-y: auto;
}

.footer-wrapper {
  background: #fff;
  padding: 20px 0 10px 0;
  border-top: 1px dashed #e1e3e5;
}

</style>
