// import request from '@/utils/request'

import http from '@/common/utils/http'

export function getElevatorRepairPage(data) {
  return http({
    url: `maintenancePlatform/getRepairOrderPage`,
    method: 'post',
    data
  })
}

// // 创建电梯报修
// export function createElevatorRepair(data) {
//   return request({
//     url: '/shmashine-maintenance/elevator-repair/create',
//     method: 'post',
//     data: data
//   })
// }

// // 更新电梯报修
// export function updateElevatorRepair(data) {
//   return request({
//     url: '/shmashine-maintenance/elevator-repair/update',
//     method: 'put',
//     data: data
//   })
// }

// // 删除电梯报修
// export function deleteElevatorRepair(id) {
//   return request({
//     url: '/shmashine-maintenance/elevator-repair/delete?id=' + id,
//     method: 'delete'
//   })
// }

// 获得电梯报修
export function getElevatorRepair(id) {
  return http({
    url: 'maintenancePlatform/getRepairDetailById?id=' + id,
    method: 'post',
    data: {}
  })
}

// // 获得电梯报修分页
// export function getElevatorRepairPage(query) {
//   return request({
//     url: '/shmashine-maintenance/elevator-repair/page',
//     method: 'get',
//     params: query
//   })
// }

// // 导出电梯报修 Excel
// export function exportElevatorRepairExcel(query) {
//   return request({
//     url: '/shmashine-maintenance/elevator-repair/export-excel',
//     method: 'get',
//     params: query,
//     responseType: 'blob'
//   })
// }

// // 创建电梯报修
// export function elevatorRepairOrderBpmInitiated(data) {
//   return request({
//     url: '/shmashine-maintenance/elevator-repair/elevatorRepairOrderBpmInitiated',
//     method: 'post',
//     data: data
//   })
// }
